import React from 'react';
import map from 'lodash/map';
import replace from 'lodash/replace';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Link from './Link';

const BlogRollTemplate = ({ posts }) => {
  const { t } = useTranslation();

  return (
    <div className="columns is-multiline">
      {map(posts, ({ node: post }) => (
        <div className="is-parent column is-6" key={post.id}>
          <article className={`blog-list-item tile is-child box notification`}>
            <header>
              {!!post.frontmatter.featuredimage && (
                <div className="featured-thumbnail">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: post.frontmatter.featuredimage,
                      alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      width:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.width,
                      height:
                        post.frontmatter.featuredimage.childImageSharp
                          .gatsbyImageData.height,
                    }}
                  />
                </div>
              )}
              <p className="post-meta">
                <Link
                  className="title has-text-primary is-size-4"
                  to={post.fields.slug}
                >
                  {post.frontmatter.title}
                </Link>
                <br />
                <span className="subtitle is-size-5 is-block">
                  {post.frontmatter.date}
                </span>
              </p>
            </header>
            <p>
              {replace(post.excerpt, /[a-zA-Z-]+/, '')}
              <br />
              <br />
              <Link className="button" to={post.fields.slug}>
                {t('blog.keep_reading')}
              </Link>
            </p>
          </article>
        </div>
      ))}
    </div>
  );
};

BlogRollTemplate.propTypes = {
  posts: PropTypes.array,
};

export default BlogRollTemplate;
